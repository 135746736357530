import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>AWS Security Essentials へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/aws-security-essentials/" target="blank">AWS Security Essentials について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
        <h5>Webex</h5>
        <p>トレーニング期間中は配信環境としてWebexを利用します。<br/>配信の他、「挙手」「グループワークでの会話」にも利用します。<br/>ログインする際には実名で（漢字・ローマ字問いません）ご登録をお願いします。<br/><a href="https://awsj-tc.s3-ap-northeast-1.amazonaws.com/Operations/Manuals/Public_Private/Tool/AWS%E3%82%AA%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%88%E3%83%AC%E3%83%BC%E3%83%8B%E3%83%B3%E3%82%B0_Webex%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%96%B9%E6%B3%95.pdf" target="blank">Webex の使い方 (PDF)</a></p>
        <hr/>
        <h5>Rocket Chat</h5>
        <p>
          トレーニング期間中は<br/>
          =====<br/>
          ・インストラクター～参加者<br/>
          ・参加者～参加者<br/>
          =====<br/>
          のテキストコミュニケーションに<b>"Rocket Chat"</b>というツールを利用します。<br/>
          基本的にWebexのチャットではなく、Rocket Chat で質問したり会話をするように統一しましょう。
          <div class="blackboard-box">
            <p><b><span class="warn">Rocket Chat とは？</span></b></p>
            <p>Slackに似たツールで絵文字スタンプやスレッド投稿が可能です</p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
        </p>
        <hr/>
        <h5>テキストのダウンロード</h5>
        <p>
          トレーニングで利用するテキストは、Gilmore Bookshelf という電子テキスト配布システムで参照します。<br/>
          下記URLから利用手順をダウンロードして、テキストの引き換えを行ってください。<br/>
          <div class="blackboard-box">
            <p><b><span class="warn">事前案内でテキストをダウンロード済みの方は、こちらの手順は不要です</span></b></p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
          <a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">テキストの利用手順 (PDF)</a>
        </p>
        <hr/>
        <h5>ハンズオン環境へのログイン</h5>
        <p>
          トレーニングでは実際に AWS のアカウントを使用してハンズオンが行える QwikLabs というシステムを利用します。<br/>
          下記 URL の利用手順を参照して、ハンズオンが実施可能な状態にしておいてください。<br/>
          <a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/QwikLabsGuide.pdf" target="blank">ハンズオン利用手順 (PDF)</a><br/>
          <a href="https://aws.qwiklab.com/" target="blank">QwikLabs へログイン</a>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          test<br/>
          <a href="#" target="blank">トレーニング付加資料</a>
        </p>
        
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <hr/>
        <h5>Day 2</h5>
        <hr/>
        <h5>Day 3</h5>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール1</h5>
        test
        <hr/>
        <h5>モジュール2</h5>
        test
        <hr/>
        <h5>モジュール3</h5>
        test
        <hr/>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>ハンズオン（Qwiklabs）</h5>
        Q. トレーニング期間中、ハンズオン環境はいつまで利用できますか？<br/>
        &nbsp;&nbsp;・トレーニング最終日の 23 時まで利用可能です。<br/>
        &nbsp;&nbsp;・ただし、3回 / Lab / 人 の制限がありますので、お気をつけください。<br/><br/>
        Q. ハンズオン環境のタイマーのような表示はなんですか？<br/>
        &nbsp;&nbsp;・ハンズオン環境は一度起動すると AWS アカウントが払い出され、タイマーによってカウントダウンが始まります。<br/>
        &nbsp;&nbsp;・タイマーが 00:00:00 になると起動したハンズオン環境へのサインインができなくなり、起動した AWS アカウント上のリソースがワイプ (削除)されます<br/><br/>
        Q. ハンズオン環境で<b><font color="red">ラボ終了</font></b>をクリックするとどうなりますか？<br/>
        &nbsp;&nbsp;・ハンズオン環境へのサインインができなくなり、起動したAWSアカウント上のリソースがワイプ (削除)されます。
        <hr/>
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから730日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a>
        <hr/>
        <h5>Rocket Chat</h5>
        Q. チャットの内容はいつでも参照できますか？<br/>
        &nbsp;&nbsp;・いいえ、トレーニング最終日の翌日を目処に削除します。
      </div>
    </main>
  );
};

export default MainContents;
